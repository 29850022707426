@import '../../assets/style/reset/index.scss';
@import '../../assets/style/theme/index.scss';
@import '../../assets/style/mixins/index.scss';
@import '../../assets/style/common/index.scss';
.newipt-item{
  margin-left: 20px;
  margin-right: 20px;
  flex: 1;
  &_title{
    display: block;
    margin-top: 20px;
    span{
      font-family: 'cadillac50';
      color: #F55641;
      font-size: 24px;
    }
  }
  &_content{
    @include flex-layout($align-items: center);
    margin-top: 30px;
    padding-bottom: 20px;
    color: #999999;
    .newipt-item_sign{
      font-family: 'cadillac50';
      font-size: 32px;
      color: #333333;
    }
    input{
      flex: 1;
      border: 0;
      color: #333333;
      font-size: 32px;
      font-family: 'cadillac50';
      margin-left: 21px;
      line-height: normal;
    }
    :-ms-input-placeholder{
      color: #999999;
    }
    .newipt-item_unit{
      padding-right: 20px;
    }
  }
  .bottomBorder{
    border-bottom: 1px solid #EAEAEA;
  }
  &_capitals{
    display: block;
    padding: 10px 0;
    font-size: 24px;
    color: #999999;
    font-family: 'cadillac50';
  }
}
