@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarOffShelve{
  width: 100%;
  height: 100%;
  padding-top: 88px;
  padding-bottom: 140px;
}
.cardWrap{
  margin-top: 30px;
  padding-bottom: 140px;
}
.title{
  display: flex;
  align-items: center;
  height: 93px;
  font-family: 'cadillac70';
  font-weight: bold;
  font-size: 28px;
  padding-left: 30px;
  color: #333333;
  border-bottom: 1px solid #D8D8D8;
}
.openTitle{
  font-family: 'cadillac50';
  font-size: 28px;
  font-weight: normal;
  color: #999999;
}

.openBg{
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  margin-left: 30px;
  margin-right: 30px;
}
.openLastBg{
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}
.switchBg div{
  font-size: 20px;
}
.switchBg{
  --checked-color: #8A1629;
}
.imageView{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.tips{
  color: #8A1629;
}