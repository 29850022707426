@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.baseButton {
    width: 710px;
    height: 88px;
    border: none;
    border-radius: 44px;
    font-size: 32px;
  }
  
  .defaultButton {
    background: #fff;
    border: 1px solid #ccc;
  }
  
  .primaryButton {
    background: #8A1629;
    color: #fff;
  }
  
  .buttonDisabled {
    opacity: 0.6;
  }
  
  .defaultButtonDisabled {
    opacity: 0.6;
  }