@import '../style/reset/index.scss';
@import '../style/theme/index.scss';
@import '../style/mixins/index.scss';
@import '../style/common/index.scss';
@font-face {
    font-family: 'cadillac35';
    src: url('hyjh35j.ttf');
}
@font-face {
    font-family: 'cadillac50';
    src: url('hyjh50j.ttf');
}
@font-face {
    font-family: 'cadillac70';
    src: url('hyjh70j.ttf');
}
@font-face {
    font-family: 'cadillac90';
    src: url('hyjh90j.ttf');
}