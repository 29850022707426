@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarDetail{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 88px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;
    .noRecord{
        display: block;
        margin: 200px auto 0;
    }
    .sliderCar {
        position: relative;
        height: 546px;
        .cardTop {
            position: absolute;
            top: 340px;
            padding: 0 20px;
            // z-index: 8;
        }
    }
    .imgHeight {
        height: 380px;
        img {
            height: 380px;
        }
    }
}
.topLeft{
    .detailMore{
        width: 40px;
        margin-left: auto;
        img{
            width: 100%;
        }
    }
    .moreContent{
        margin-top: -10px;
        padding: 0 60px;
        font-size: 24px;
        color: #666666;
        // display: flex;
        // flex-direction: column;
        background: rgba(255,255,255,.9);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        position: relative;
        span{
            display: block;
            margin-bottom: -10px;
        }
    }
}