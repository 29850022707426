@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.imageCard {
    position: relative;
    width: 223px;
    height: 150px;
  }
  
  .imageCardImage {
    width: 223px;
    height: 150px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .imageCardImage img {
    width: 100%;
    height: 100%;
  }
  
  .removeImageButton {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  
  .removeImageButton img {
    width: 100%;
    height: 100%;
  }
  