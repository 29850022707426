@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.topNavBar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 88px;
    line-height: 88px;
    text-align: center;
    font-size: 36px;
    background: #fff;
}

.rightHandle {
    position: absolute;
    right: 20px;
    top: 0;
    a {
        color: #FF6600;
    }
    // img.share-img{
    //     width: 35px;
    //     height: 35px;
    //     vertical-align: middle;
    // }
}
.leftHandle{
    position: absolute;
    left: 20px;
    top: 0;
}
