.am-modal-transparent{
  width: 285px!important;
  .am-modal-button-group-h .am-modal-button{
    color: #FF6600;
  }
  .am-modal-button-group-h .am-modal-button:first-child{
    color: #666666;
  }
  .am-modal-alert-content{
    color: #666666;
    font-size: 14px;
  }
  .am-modal-title{
    color: #333333;
    font-size: 15px;
  }
  .am-modal-footer a{
    font-size: 15px;
  }
}
