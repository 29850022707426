@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.Box{
    position: relative;
    width: 95%;
    height: 90px;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    background: #fff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    flex-direction: column;
}
.titleName{
    font-family: 'cadillac70';
    font-size: 24px;
    color:#FF6600;
}
.tipName{
    font-family: 'cadillac70';
    font-size: 16px;
    color:#4169E1;
}
.inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }