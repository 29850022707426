@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarList{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 173px;
    padding-bottom: 140px;
    display: flex;
    flex-direction: column;
    background: #F5F5F5;
    .noRecord{
        display: block;
        margin: 200px auto 0;
    }
    .adm-dropdown-item{
        background: #ee3943;
    }
}
.topLeft{

    .detailMore{
        width: 40px;
        margin-left: auto;
        img{
            width: 100%;
        }
    }
    .moreContent{
        margin-top: -10px;
        padding: 0 60px;
        font-size: 24px;
        color: #666666;
        // display: flex;
        // flex-direction: column;
        background: rgba(255,255,255,.9);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        position: relative;
        span{
            display: block;
            margin-bottom: -10px;
        }
    }
}
.topSelect{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 85px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    background: #ffffff;
    position: fixed;
    top: 88px;
    z-index: 1;
}
.topSelectLeft{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #f5f5f5;
    width: 48%;
    height: 55px;
    margin-right: 15px;
}
.topSelectRight{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #f5f5f5;
    width: 48%;
    height: 55px;
    margin-left: 15px;
}

.topSelectHighLight{
    background: linear-gradient(270deg, rgba(138, 22, 41, 0.8) 0%, #8A1629 100%);
}
.selectText{
    font-family: 'cadillac50';
    color: #656565;
    font-size: 28px;
}
.selectTextWhite{
    color:#ffffff;
}
.listViewBg{
    background: #f5f5f5;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.carImg{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 190px;
    height: 190px;
}
.carNumber{
    width: 190px;
    height: 35px;
    max-width: 190px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    white-space: nowrap;
    padding-left: 10px;
    display: flex;
    align-items:center;
    color: #ffffff;
    font-family: 'cadillac50';
    font-size: 20px;
    background-image: url('../../../../assets/image/icon_car_number.png');
    background-size: 100%,100%;
}
.carNumberImg{
    width: 190px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 35px;
}
.leftContent{
    position: relative;
    width: 190px;
    height: 190px;
}
.rightContent{
    height: 180px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ffffff;
    flex-grow: 1;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 8px;
    padding-left: 15px;
    max-width: 70%;
}
.title{
    font-family: 'cadillac70';
    color:#333333;
    font-weight: bold;
    font-size: 28px;
    margin-top: 15px;
}
.contentBg{
    margin-top: 10px;
}
.content{
    font-family: 'cadillac50';
    color: #676767;
    font-size: 24px;
}
.saleContentBg{
    font-family: 'cadillac50';
    color: #676767;
    font-size: 24px;
}
.mallBg{
    position: absolute;
    bottom: 0px;
    margin-bottom: 15px;
    border-radius: 15px;
    border: 1px solid #8A1629;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
    font-family: 'cadillac50';
    color: #8A1629;
    font-size: 18px;
}
.onSale{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 38px;
    position:absolute;
    margin-bottom: 15px;
    bottom: 0px;
    right: 0px;
    font-family: 'cadillac50';
    color: #ffffff;
    font-size: 24px;
    background-size: 100%,100%;
}
.isSale{
    background-image: url('../../../../assets/image/icon_on_sale.png');
}
.notSale{
    background-image: url('../../../../assets/image/icon_not_sale.png');
}
.bottomCarView{
    position: relative;
    width: 100%;
    flex-grow: 10;
}
.vehiclePopup{
    position: fixed;
    top: 173px;
    z-index: 3;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.vinInputBg{
    width: 100%;
    height: 56px;
    background: #F5F5F5;
    border-radius: 4px;
    margin: 0px 30px;
    padding-left: 30px;
    margin-top: 30px;
}
.popupTitleBg{
    font-family: 'cadillac50';
    font-size: 28px;
    color: #343434;
    margin-left: 30px;
    margin-top: 30px;
}
.maskBg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 173px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
}
.vehiclePopupBg{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 92px;
    align-items: center;
}
.vehiclePopupBgSelect{
    background: #FFF0F0;
}
.vehicleNameBg{
    font-family: 'cadillac50';
    font-size: 28px;
    color:#343434;
    margin-left: 30px;
}
.placeholderBg{
    width: 100%;
    flex-grow: 1;
    background: #000000;
    opacity: 30%;
}
.bottomButtonBg{
    position: relative;
    bottom: 0;
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
}
.bottomButtonLeft{
    width: 40%;
    height: 100%;
    background: #BFBFBF;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottomButtonRight{
    width: 58%;
    height: 100%;
    background: #8A1629;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupHeader{
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.popup{
    top: 173px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 50%;
}
.maskPopup{
    top: 173px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.popupVisible{
    display: unset;
}
.popupNone{
    display: none;
}
.noImgParentBg{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noImg{
    width: 100px;
    height: 80px;
}