@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.pickerItem {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .itemContent {
    width: 440px;
    // height: 100px;
    // line-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    margin-right: 60px;
    text-align: left;
  }
  
  .textR {
    text-align: right;
  }
  
  .isNoValue {
    color: #999999;
  }
  
  .iconArrow {
    position: absolute;
    right: 20px;
  }
  