@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.seeOperate{
    color: #FF6600;
  }
  .selfCheck{
    .newCard_labelItem__AKJhv{
      padding-bottom: none!important;
    }
  }
  