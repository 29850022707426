// 主色调
$primaryColor: #FF6600;
// 次色调
$secondaryColor: #FF932E;
// 字体颜色
$normalFontColor: #333;
// 标题颜色
$titleFontColor: #333;
// 标签字体颜色
$labelFontColor: #666;
// 小标题颜色
$smallTitleFontColor: #888;
// 字体淡色
$lightFontColor: #999;
// 线条颜色
$lineColor: #EAEAEA;
// 页面背景色
$pageBackgroundColor: #f5f5f5;

// 默认按钮字体大小
$buttonNormalFontSize: 32px;
