@import '../../assets/style/reset/index.scss';
@import '../../assets/style/theme/index.scss';
@import '../../assets/style/mixins/index.scss';
@import '../../assets/style/common/index.scss';
.noData{
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 36px;
      height: 36px;
      color: #BDBDBD;
      margin-right: 21px;
    }
    span{
      font-size: 26px;
      color: #999999;
    }
  }
  