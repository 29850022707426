@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.dealerEcharts{
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 88px;
  padding-bottom: 88px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
}
.dealerCode{
  height: 88px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rankContent{
  margin: 20px;
  border-radius: 5px;
  background: #fff;
  padding-bottom: 10px;
  margin-top: 30px;
}

.bigTitle{
  font-size: 34px;
  font-weight: bold;
  margin-left: 85px;
  margin-top: 30px;
}

.content{
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataContent{
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
}

.leftArrow{
  width: 22px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: 20px;
  background-image: url('../../../../assets/image/zuo2.png');
}

.rightArrow{
  width: 22px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-left: 20px;
  background-image: url('../../../../assets/image/you1.png');
}

.itemTotal{
  display: block;
  color: #ffffff;
  font-size: 16px;
  line-height: 70px;
  margin-left: 30px;
}

.itemValue{
  display: block;
  color: #fff;
  font-size: 30px;
  margin-left: 30px;
  line-height: 15px;
}


.img0 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url('../../../../assets/image/tp1.png');
}
.img1 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url('../../../../assets/image/tp2.png');
}
.img2 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url('../../../../assets/image/tp3.png');
}
.img3 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url('../../../../assets/image/tp4.png');
}
.img4 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background-image: url('../../../../assets/image/tp5.png');
}
.img5 {
  width: 220px;
  height: 100px;
  background: #fff;
  display: inline-block;
  background-size: 100%;
  margin-left: 5px;
  margin-right: 5px;
  background-image: url('../../../../assets/image/tp6.png');
}

.saleContent{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  span{
    text-align: center;
    flex: 5;
    white-space: nowrap;;
  }
}

.rangeIcon1 {
  flex: 1;
  width: 15px;
  height: 30px;
  background-size: 100% 100%;
  background-image: url('../../../../assets/image/tp1.png');
}
.rangeIcon2 {
  flex: 1;
  width: 15px;
  height: 30px;
  background-size: 100% 100%;
  background-image: url('../../../../assets/image/scw.png');
}
.rangeIcon3 {
  flex: 1;
  width: 15px;
  height: 30px;
  background-size: 100% 100%;
  background-image: url('../../../../assets/image/ml.png');
}
.rangeIcon4 {
  flex: 1;
  width: 15px;
  height: 30px;
  background-size: 100% 100%;
  background-image: url('../../../../assets/image/jj.png');
}
.flex4{
  flex: 4;
  margin-left: 20px;
}
.flex15{
  flex: 15;
  margin-left: 10px;
}
.saleChampionTitleBg{
  font-size: 24px;
  color: #000000;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}

.saleChampionTextBg{
  font-size: 16px;
  color: #333333;
}
.progressContainer{
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}

.taskTitie{
  font-size: 14px;
  padding-bottom: 20px;
}

.progressNum{
  display: flex;
  justify-content: center;
  align-items: center;
}