// flex布局
@mixin flex-layout($justify-content: flex-start, $align-items: stretch) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

// 页面顶部距离
@mixin page-top($padding-top: 88px) {
  padding-top: $padding-top;
}
// 页面底部距离
@mixin page-bottom($padding-bottom: 140px) {
  padding-bottom: $padding-bottom;
}
