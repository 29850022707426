@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.popupBox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popupBox .popupBoxMask {
    background: #000;
  }
  
  .popupBoxContent {
    position: absolute;
    width: 100%;
  }
  
  .imageListBox ul {
    height: 100vh !important;
  }
  
  .imageListBox li {
    position: absolute;
    left: 0;
    top: 50% !important;
    transform: translateY(-50%);
  }
  
  .imageItem {
    display: inline-block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50% !important;
    transform: translateY(-50%);
  }
  
  .imageItem img {
    width: 100%;
  }
  
  .downloadImage {
    width: 100px;
    height: 100px;
    background: #2C2C2C;
    border-radius: 10px;
    position: absolute;
    right: 40px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .downloadImage img {
    width: 46px;
  }
  
  .NewImagePopup{
    width: 100%;
    position: fixed;
    top: 70px;
    // right: 10px;
    z-index: 999999;
    background: #000;
    text-align: center;
    color: #fff;
    >div.imgTop{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      font-size: 40px;
      img{
        width: 50px;
        vertical-align: text-top;
      }
    }
    >div.imgBottom{
      width: 100%;
      position: fixed;
      bottom: 50px;
      font-size: 28px;
      text-align: left;
      padding-left: 22px;
    }
  }
  