@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.footer{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    background: #f5f5f5;
    padding: 20px 0 24px;
    text-align: center;
  }
  