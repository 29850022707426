@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.configItem{
  overflow: hidden;
  margin: 15px 30px;
}
.configItemLabel{
  float: left;
  max-width: 50%;
  color: #666;
}
.configItemValue{
  float: right;
  max-width: 50%;
}