:global {
    .am-textarea-item {
        background-color: transparent;
        padding: 0;
    }
    .am-textarea-control {
        padding: 0;
    }
    .am-textarea-item .am-textarea-control textarea {
        font-size: 14px;
    }
    .am-textarea-control textarea:disabled {
        background-color: transparent;
        color: #000;
    }
}