@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.new-moreUpload{
    // margin: 0 10px;
    // @include flex-layout($justify-content: space-between, $align-items: center);
    // flex-wrap: wrap
    &_info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 84px;
      margin-left: 20px;
      padding-right: 22px;
      position: relative;
      // &::before{
        // content: '';
        // width:5px;
        // height:24px;
        // position: absolute;
        // left: 0;
        // background:linear-gradient(-90deg,rgba(255,102,0,1) 0%,rgba(255,147,46,1) 100%);
      // }
    }
    &_optVal{
      display: inline-block;
      padding: 10px 17px 9px;
      font-size: 22px;
      color: #FF6600;
      border: 1px solid rgba(255,102,0,1);
      border-radius: 6px;
    }
    &_modify{
      margin-right: 20px;
    }
    &_item{
      display: inline-block;
      margin: 0 10px;
    }
    .file-ipt{
      display: none;
    }
    &_title{
      font-family: 'cadillac70';
      font-size: 28px;
      color: #333333;
      font-weight: bold;
    }
  }
  