@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.cardWrap{
    margin-top: 100px;
    padding-bottom: 140px;
  }
  .title{
    display: flex;
    align-items: center;
    width: 100%;
    height: 93px;
    font-family: 'cadillac70';
    font-weight: bold;
    font-size: 28px;
    padding-left: 30px;
    border-bottom: 1px solid #D8D8D8;
  }
.textarea{
  width: 100%;
  height: 200px;
  border: 0px;
  font-size: 32px;
}

.am-textarea-control textarea {
  font-size: 28px;
}