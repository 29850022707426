@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.cardWrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 140px;
}
.title{
  display: flex;
  align-items: center;
  height: 93px;
  font-family: 'cadillac70';
  font-weight: bold;
  font-size: 28px;
  padding-left: 20px;
  color: #333333;
  border-bottom: 1px solid #D8D8D8;
}
.tipText{
  font-size: 24px;
  color: #999999;
  margin-right: 20px;
}
.vinText{
  font-family: 'cadillac70';
  font-size: 16px;
  border-radius: 5px;
  color:#FF6600;
  padding: 7px;
  border: 1px solid #FF6600;
}
.pickerList{
  border: 0px;
  height: 88px;
  margin-left: 20px;
  margin-right: 20px;
}
.areaListItem{
  height: 88px;
  padding-left: 0px !important;
}
.areaListItem:first-child:first-child{
  min-width: 190px !important;
}
.areaListItem div{
  font-size: 28px !important;
  color: #666 !important;
}
.areaListItem span{
  color:#ee3943 !important;
}
