.iconback{
  font-size: 40px;
}
.iconclose{
  font-size: 40px;
}
.iconarrow{
  color: #A0A6B3;
  font-size: 12px;
}
.iconcalendar, .iconpersonal{
  font-size: 48px;
}
.iconcheck, .iconauction{
  font-size: 40px;
}
.icondown{
  font-size: 30px;
}