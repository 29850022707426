@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.topBg{
  padding: 0px 30px;
  width: 100%;
  height: fit-content;
  margin-top: 30px;
}
.title{
  font-family: 'cadillac50';
  font-size: 28px;
  color: #343434;
}
.contentBg{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.item{
  position: relative;
}
.itemBg{
  font-family: 'cadillac50';
  font-size: 24px;
  width: 162px;
  height: 56px;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.itemBgSelected{
  background: #FAE8E8;
  color: #8A1629;
}
.itemBgUnselect{
  background: #F5F5F5;
  color: #676767;
}
.labelVisibel{
  display: none;
}
.imgBg{
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}