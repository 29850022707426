:global {
    .slider-slide {
        touch-action: none !important;
    }
    .am-wingblank.am-wingblank-lg {
        margin: 0 !important;
    }
    .am-wingblank {
        margin: 0 !important;
    }
    .slider{
      touch-action: none;
    }
    .am-carousel-wrap-dot>span {
        border-radius: 0 !important;
        width: 6px !important;
        height: 6px !important;
        background: none !important;
        margin: 0 2px !important;
        box-shadow: black 2px 2px 16px !important;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .am-carousel-wrap-dot-active>span {
        background-color: #FF9100 !important;
    }
    .slider-decorator-0 {
        bottom: 41px !important;
    }
}