@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.addItemBox {
    position: relative;
    width: 223px;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    background: #fff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .addItem {
    font-size: 24px;
    text-align: center;
    color: #DDE2E6;
    position: absolute;
    top: 59px;
    z-index: 1;
  }
  
  .addItem img.addLogo {
    width: 38px;
    height: 32px;
    // margin-bottom: 10px;
  }
  
  .bgImage{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }
  
  .addItem p {
    transform: scale(.7);
  }
  
  .inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
  