@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarPhotos{
  width: 100%;
  height: 100%;
  padding-top: 88px;
  padding-bottom: 140px;
}
.baseParamsTitle{
  font-family: 'cadillac70';
  position: relative;
  padding: 30px 0 40px 20px;
  font-size: 28px;
  font-weight:bold;
  color:#333333;
  // &::before{
    // display: inline-block;
    // content: '';
    // width:5px;
    // height:24px;
    // position: absolute;
    // left: 20px;
    // background:linear-gradient(-90deg,rgba(255,102,0,1) 0%,rgba(255,147,46,1) 100%);
  // }
}