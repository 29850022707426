@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.wingDom{
    position: relative;
  }
  .picNum{
    position: absolute;
    bottom: 34px;
    right: 20px;
    height:36px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background:rgba(0,0,0,1);
    opacity: 0.5;
    border-radius: 18px;
    color: #ffffff;
    img{
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    span{
      font-size: 20px;
    }
  }
  .noImage{
    height: 380px;
  }
  .photoNumTop{
    bottom: 16px;
  }
  