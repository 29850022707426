@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.card{
    // width: 710px;
    background: #fff;
    margin: 20px auto;
    overflow: hidden;
  }
  .boxShadow{
    box-shadow: 0 2px 18px 2px rgba(#ddd, 0.5);
  }
  .borderRadius{
    border-radius: 10px;
  }