@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.backButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    position: absolute;
    left: 0;
    top: 0;
  }