@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.newCard{
    margin: 20px;
    border-radius: 10px;
    background: #ffffff;
  }
  .carTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    margin-left: 20px;
    padding-right: 22px;
    position: relative;
    border-bottom: 1px solid #EAEAEA;
    &::before{
      content: '';
      width:5px;
      height:24px;
      position: absolute;
      left: -20px;
      background:#8A1629;
    }
    .carTit{
      font-size: 26px;
      color: #8A1629;
      font-weight:500;
    }
    .operate{
      display: flex;
      font-size: 24px;
      color: #FF6600;
      img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
      }
      >div{
        display: flex;
        align-items: center;
      }
      >div span{
        font-size: 24px;
      }
      >div:not(:last-child) {
        margin-right: 30px;
      }
    }
    .imgStyle{
      img{
        height: 10px;
      }
    }
  }
  .labelItem{
    display: flex;
    align-items: center;
    font-size: 26px;
    color: #666666;
    padding: 30px 0 0 20px;
    &:last-child{
      padding-bottom: 30px;
    }
    .label{
      font-family: 'cadillac50';
      color: #666666;
      align-self: flex-start;
      font-size: 28px;
    }
    .content{
      font-family: 'cadillac50';
      flex: 1;
      padding-right: 22px;
      color: #333333;
      font-size: 28px;
      text-align: end;
    }
  }
  .noPadding{
    padding-bottom: 0!important;
  }
  .noBottomBorder{
    border-bottom: 0;
  }
  .buttonStyle{
    display: inline-block;
    padding: 10px 17px 9px;
    font-size: 22px;
    color: #FFF;
    background-image: url('../../../assets//image//icon_view_bg.png');
    background-size: 100%, 100%;
  }
  