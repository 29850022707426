@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.headerBox {
    display: flex;
    align-items: center;
  }
  .titleBeforeLine{
    width: 5px;
    height: 24px;
    background: #8A1629;
    margin-right: 16px;
  }
  .noTitleBeforeLine{
    background: #fff;
  }
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 88px;
    background: #fff;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #EAEAEA;
    color: #8A1629;
    font-size: 28px;
    font-family: 'cadillac70';
  }
  
  .headerIcon {
    display: inline-block;
    width: 5px;
    height: 24px;
    background: linear-gradient(-90deg, #ff6600 0%, #ff932e 100%);
    margin-right: 16px;
  }
  
  .headerHandle {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    margin-right: 20px;
  }
  .content{
    display: flex;
    flex-direction: column;
  }