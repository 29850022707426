@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.titleSingleUpload{
    display: inline-block;
    // margin: 0 10px 30px;
    margin-bottom: 30px;
  }
  .credentialsImageName{
    font-size: 24px;
    color: #999;
    margin-top: 20px;
    text-align: center;
    height: 24px;
    width: auto;
  }
  .requireStyle{
    &::after{
      content: '*';
      color: #FF0101;
    }
  }
  