@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.labelItem{
    display: flex;
    align-items: center;
    height: 88px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
  }
  .label{
    min-width: 190px;
    white-space: nowrap;
    color: #666;
  }
  .label span{
    color: #F55641;
    font-size: 24px;
  }
  .changeWidth{
    width: 281px;
  }
  .content{
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    >div{
      width: 100%;
    }
  }
  .handleContent{
    display: flex;
    align-items: center;
    // position: absolute;
    right: 0px;
    height: 100%;
    margin-right: 20px;
  }
  