:global {
    .am-segment-item {
        color: #333 !important;
        border: 0 !important;
    }
    .am-segment-item-selected {
        background: none !important;
        color: #FF6600 !important;
        &::after {
            content: '';
            position: absolute;
            width: 27px;
            height: 2px;
            background-color: #FF6600;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}