@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarHostling{
  width: 100%;
  height: 100%;
  padding-top: 88px;
  padding-bottom: 140px;
}
.cardWrap{
  margin-top: 30px;
  padding-bottom: 140px;
}
.title{
  display: flex;
  align-items: center;
  width: 100%;
  height: 93px;
  font-family: 'cadillac70';
  font-weight: bold;
  font-size: 28px;
  padding-left: 30px;
  border-bottom: 1px solid #D8D8D8;
}
.isHasPolishedBg{
  display: flex;
  margin-left: 30px;
}

.checkBox{
  width: 30px;
  height: 20px;
}
.switchBg div{
  font-size: 20px;
}
.switchBg{
  --checked-color: #8A1629;
}