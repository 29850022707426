@import '../../../assets/style/reset/index.scss';
@import '../../../assets/style/theme/index.scss';
@import '../../../assets/style/mixins/index.scss';
@import '../../../assets/style/common/index.scss';
.config-star{
    padding: 30px 0 0 20px;
    span{
      margin-right: 30px;
    }
  }
  