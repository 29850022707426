:global{
  .am-tabs{
    margin-top: 18px;
  }
  .am-tabs-default-bar{
    z-index: 0;
  }
  .am-tabs-default-bar-top{
    background-color: transparent !important;
  }
  .am-tabs-default-bar-tab{
    height: 30px;
    font-size: 13px;
  }
  .am-tabs-default-bar-tab::after{
    background-color: transparent !important;
  }
  .am-tabs-default-bar-tab-active{
    color: #FF6600;
  }
  .am-tabs-default-bar-underline{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background-color: transparent !important;
  }
  .am-tabs-default-bar-underline:after{
    content: '';
    width: 27px;
    height: 2px;
    background-color: #FF6600;
    border-radius: 2px;
  }
}
