* {
  font-size: 28px;
}

html,
body {
  // font-family: PingFangSC, sans-serif !important;
  background: #f5f5f5 !important;
  color: $normalFontColor;
}

:global {
  .noBorder {
    border: none !important;
  }

  .page {
    @include page-top();
    @include page-bottom();
  }
}

@import './model/antd-tabs.scss';
@import './model/antd-slider.scss';
@import './model/antd-segmented.scss';
@import './model/antd-textarea.scss';
@import './model/antd-modal.scss';
