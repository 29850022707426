@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.CarConfigureInfo{
    height: 180px;
    background-image: url(../../../../assets/image/icon_configure_bg.png);
    background-size: 100%, 100%;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.carType {
    font-family: 'cadillac70';
    font-size: 30px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    color: #fff;
    line-height: 40px;
  }
  
  .configExplain {
    margin-left: 20px;
    font-size: 24px;
    color: #fff;
    font-family: 'cadillac50';
  }
  
  .configExplain span {
    display: inline-block;
    margin-left: 30px;
    margin-right: 5px;
  }
  
  .configExplain span:nth-child(1) {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: #ffffff;
  }
  
  .configExplain span:nth-child(2) {
    width: 14px;
    height: 14px;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .configExplain span:nth-child(3) {
    width: 14px;
    height: 14px;
    border: 1px solid #bbbbbb;
    border-radius: 50%;
    box-sizing: border-box;
  }
  