@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.carMsgList{
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 88px;
  display: flex;
  flex-direction: column;
}
.itemBg{
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.leftContent{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 160px;
  margin-left: 30px;
  border-radius: 16px 0px 0px 16px;
}
.leftContentPass{
  background: #63B07F;
}
.leftContentNotPass{
  background: #BF5E5E;
}
.imgBg{
  width: 30px;
  height: 30px;
}
.rightContent{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 190px;
  border-radius: 16px;
  margin-right: 30px;
  background: #fff;
  padding-left: 30px;
}
.title{
  margin-top: 30px;
  color: #333333;
  font-size: 28px;
  font-weight: bold;
}
.content{
  margin-top: 15px;
  color: #666666;
  font-size: 24px;
}
.bottomBtnBg{
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.time{
  color: #9A9A9A;
  font-size: 24px;
}
.deleteBtnBg{
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #8A1629;
  background-color: #fff;
  color: #8A1629;
  margin-right: 30px;
}