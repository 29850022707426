@import '../../../../assets/style/reset/index.scss';
@import '../../../../assets/style/theme/index.scss';
@import '../../../../assets/style/mixins/index.scss';
@import '../../../../assets/style/common/index.scss';
.baseParamsTitle{
    position: relative;
    padding: 30px 0 40px 40px;
    font-size: 26px;
    font-weight: bold;
    color:rgba(51, 51, 51, 1);
    &::before{
      display: inline-block;
      content: '';
      width:5px;
      height:24px;
      position: absolute;
      left: 20px;
      background:#8A1629;
    }
}
.baseParams{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
  